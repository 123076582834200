.Tab {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #fff
}

.signUp,
.signIn {
      background-color: #A2A2A1FF;
      width: 50%;
      font-size: 22px;
      color: #fff;
      padding: 10px;
      text-align: center;
}

.signIn:hover,
.signUp:hover {
      text-decoration: none;
      color: #fff;
}

.active {
      color: #e86b19;
}

span.icon {
      background: url('g-normal.png') transparent 5px 50% no-repeat;
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
}