@font-face {
      font-family: "Roboto";
      src: url("./fonts/Roboto-Light.woff2");
      src: local("Roboto Light"), local("Roboto-Light");
      font-weight: 300;
      font-style: normal;
}

@font-face {
      font-family: "Roboto";
      src: url("./fonts/Roboto-Regular.woff2");
      src: local("Roboto"), local("Roboto-Regular"),
            url("./fonts/Roboto-Regular.woff2") format("woff2");
      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: "Roboto";
      src: url("./fonts/Roboto-Medium.woff2");
      src: local("Roboto Medium"), local("Roboto-Medium"),
            url("./fonts/Roboto-Medium.woff2") format("woff2");
      font-weight: 500;
      font-style: normal;
}

@font-face {
      font-family: "Roboto";
      src: url("./fonts/Roboto-Bold.woff2");
      src: local("Roboto Bold"), local("Roboto-Bold"),
            url("./fonts/Roboto-Bold.woff2") format("woff2");
      font-weight: bold;
      font-style: normal;
}

img {
      max-width: 100%;
}

.page-header {
      padding: 10px 0 20px;
}

.page-header-kyc {
      padding-top: 14px;
      padding-bottom: 25px;
}

.page-header-kyc div[class*="col-"] {
      padding-left: 30px;
      padding-right: 30px;
}

.page-header-kyc .page-title {
      font-weight: 400;
}

.page-header-kyc p {
      font-size: 1em;
}

.page-title {
      color: #e76b19;
      font-size: 1.5em;
      font-weight: 300;
      line-height: 1;
      letter-spacing: -0.01em;
      margin-bottom: 7px;
}

.page-user {
      min-height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
}

.page-ath-wrap {
      display: flex;
      min-height: 100vh;
}

.page-ath-content {
      background: #fff;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: right;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.page-ath-heading {
      font-size: 1.8em;
      font-weight: 300;
      letter-spacing: -0.025em;
      color: #e76b19;
      line-height: 1.2;
      padding-bottom: 15px;
}

.page-ath-heading small {
      display: block;
      font-weight: 400;
      font-size: 0.66em;
      color: #495463;
      letter-spacing: normal;
      margin-top: 10px;
}

.page-ath-heading span {
      display: block;
      font-weight: 400;
      font-size: 0.61em;
      color: #495463;
      letter-spacing: normal;
      line-height: 1.62;
      padding-top: 15px;
}

.page-ath-form,
.page-ath-header,
.page-ath-footer,
.page-ath-text {
      width: 440px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0 20px;
}

.page-ath-form {
      padding-top: 20px;
      padding-bottom: 20px;
}

.page-ath-header {
      padding-top: 20px;
}

.page-ath-footer {
      padding-bottom: 30px;
}

.page-ath-gfx {
      /* background: #253992; */
      background-size: cover;
      background-position: 50% 50%;
      display: none;
}

@media (min-width: 576px) {
      .page-header {
            padding: 15px 0 45px;
      }

      .page-header-kyc .page-title {
            font-weight: 300;
            margin-bottom: 12px;
      }

      .page-header-kyc p {
            font-size: 1.2em;
      }

      .page-title {
            font-size: 2.57em;
      }

      .page-ath-heading {
            padding-bottom: 23px;
            font-size: 2.8em;
      }

      .page-ath-heading small {
            font-size: 0.46em;
      }

      .page-ath-heading span {
            font-size: 0.41em;
      }
}

@media (min-width: 768px) {
      .page-ath-content {
            width: 38%;
      }

      .page-ath-gfx {
            width: 62%;
            display: flex;
            /* align-items: center */
      }
}

@media (min-width: 992px) {
      .page-header {
            padding: 15px 0 60px;
      }
}

@media (min-width: 1200px) {
      .page-ath-content {
            width: 38%;
      }

      .page-ath-gfx {
            width: 62%;
      }
}

.page-ath-logo .fs-5 {
      font-size: 44px !important;
}

.page-ath-logo .fs-5 strong {
      font-weight: bold;
}

.input-item {
      position: relative;
      padding-bottom: 10px;
}

.input-bordered {
      border-radius: 4px;
      border: 1px solid #d2dde9;
      width: 100%;
      padding: 10px 15px;
      line-height: 20px;
      font-size: 0.9em;
      color: rgba(73, 84, 99, 0.7);
      transition: all 0.4s;
}

.gaps-2x {
      height: 20px;
}

.page-ath-footer {
      padding-bottom: 30px;
}

.footer-links {
      list-style-type: none;
      margin: 0;
      padding: 0;
}

.footer-links {
      display: flex;
      flex-wrap: wrap;
      margin: 3px -15px;
}

.footer-links li {
      padding: 2px 15px;
      font-size: 13px;
      color: #758698;
}

/* Page Summary */
.page-summary {
      /* height: calc(100vh - 240px); */
      width: 100%;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.page-brand {
      /* height: 25vh; */
      /* background-color: #fff; */
      margin-top: 10px;
      border-radius: 10px;
}

.page-footer {
      /* float: right; */
      margin-top: 10px;
      align-items: center;
}

.page-footer ul {
      display: flex;
      list-style-type: none;
}

.page-footer ul li {
      padding-left: 30px;
}

.page-summary__header {
      padding: 10px 20px;
      border-bottom: 1px solid rgb(224, 223, 223);
}

.page-summary__title {
      font-size: 24px;
      font-weight: 600;
}

.page-summary__body {
      height: 440px;
      padding: 10px 20px;
      overflow: auto;
}

/* .page-summary__body p {
    margin-bottom: 0 !important;
} */

.react-datepicker-popper[data-placement^="top"]
      .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
      .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::after {
      left: -30px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
      top: 12px !important;
}
