:root {
      --color-accent: #e86b19;
      --color-light: rgba(159, 157, 157, 0.2);
}

.profile__sidebar {
      min-height:100%;
      padding: 0;
      border-radius: 0;
      width: 250px;
}

.profile-navigation .main-dashboard {
      border: 1px solid rgba(0, 0, 0, 0.175);
      background-color: white!important;
}

.profile__sidebar-content {
      width: calc(100% - 250px);
      background-color: #fff;
      border-left: 1px solid rgba(0, 0, 0, 0.175) !important;
      border-color: rgba(0, 0, 0, 0.175);
      min-height: calc(100vh - (3.5rem + 6px));
      padding: 10px 20px;
}

.profile__sidebar .list-group  {
      --bs-list-group-border-width: 0!important;
}

.profile__sidebar .card-body {
      padding-left: 5px;
      padding-top: 10px;
      padding: 0px;
} 

.profile__sidebar .list-group-item:hover {
      background-color: var(--color-light);
      border-radius: 0;
}

.profile__sidebar .list-group-item {
      padding: 10px 20px;
      width: 100%;
}

.profile__sidebar .list-group-item > .active {
      border-left: 3px solid black
}

.profile__sidebar .list-group-item.active {
      border-left: 3px solid black;
      background-color: transparent !important;
      color: var(--color-accent);
      border-radius: 0 !important;
}
