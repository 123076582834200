:root {
      --color-accent: #e86b19;
      --color-border-base: rgb(170, 172, 174);
}

a:hover {
      color: var(--color-accent);
}

body {
      background-color: #e2e8f0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
}

/* Firefox */
input[type="number"] {
      -moz-appearance: textfield;
}

.sidebar-container {
      /* padding-left: 40px; */
      padding-top: 30px;
      width: 250px;
      overflow-y: hidden;
      height: 100vh;
      position: fixed;
      top: 0;
}

.logo {
      font-size: 22px;
      color: var(--color-accent);
}

.mobile-logo {
      font-size: 28px;
      color: var(--color-accent);
      box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
      background-color: #fff;
      padding: 2.5px 16px;
      border-radius: 1rem;
      padding-bottom: 5px;
      margin-top: 4px;
}

.bg-gray {
      color: rgb(26, 10, 68);
      /* border-radius: 20px; */
      background-color: rgba(122, 122, 122, 0.2);
      max-width: 100%;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.pb-25 {
      padding-bottom: 25px;
}

.form-control:focus {
      box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

* .fa {
      color: rgb(26, 10, 68);
}

a {
      text-decoration: none;
      color: #000;
}

/* header */
.header {
      padding: 30px;
      /* margin-right: 10px; */
      flex: 1 1 0%;
      margin-left: 250px;
      width: calc(100% - 250px);
}

.downbar-mobile-navigation {
      display: none;
}

@media (max-width: 1034px) {
      .header {
            margin-left: 0;
      }

      .sidebar-container {
            display: none;
      }

      .downbar-mobile-navigation {
            display: block;
            margin-bottom: 0.8rem;
      }
}

.table tr td {
      vertical-align: middle;
}

.row {
      align-items: center;
      justify-content: flex-start;
}

/* search */
.small-width {
      border-radius: 15px;
      color: #555;
}

/* side-bar */
.right-margin {
      position: relative;
      right: -70px;
}

.profile img {
      border-radius: 50%;
      object-fit: cover;
      margin-left: -30px;
}

.inside {
      justify-content: flex-end;
}

.lan-select select {
      border: none;
}

.side-by {
      display: flex;
      padding: 10px;
}

.nav {
      margin-left: -1.5rem;
      height: calc(100% - (4rem + 80px));
      overflow-x: hidden;
}

.icon,
.title {
      padding: 5px;
      vertical-align: middle;
}

.side-by svg {
      width: 32px;
}

i.icon {
      font-size: 20px;
      /* color: var(--color-accent); */
}

.down {
      margin-top: 100px;
      /* position: absolute;
    bottom: 0; */
}

/* main dashboard */
.main-dashboard {
      /* background-color: #fff; */
      width: 100%;
      align-items: flex-start;
      position: relative;
      top: -90;
      /* padding: 20px; */
      margin-bottom: 30px;
}

.nopadding {
      padding: 0;
      margin: 0;
}

.row {
      align-items: flex-start;
}

.dash-icon .fa {
      font-size: 50px;
      padding: 5px 0 5px 0;
}

.recent select {
      width: 50%;
}

.dash-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
      padding: 10px;
      margin-bottom: px;
}

.text-contain {
      font-weight: 500;
      font-size: 14px;
}

.dash-pad {
      padding: 20px;
}

.dash-pad p {
      font-size: 10px;
      color: #555;
}

.money-text {
      font-weight: 600;
}

.dash-margin2 {
      margin: 0 10px;
}

.recent .fa {
      font-size: 20px;
}

.text-transform,
.tran-price {
      font-size: 14px;
}

.tran-price {
      font-weight: 600;
}

.date {
      font-size: 12px;
      color: #555;
      font-weight: 400;
}

.dash-pad2 {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-bottom: 0;
}

.event-state {
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

/* side expenditure bar */
.side-margin {
      margin-top: 30px;
      padding: 20px;
}

.spent-money select {
      height: 35px;
      background: transparent;
      outline: none;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      width: 70%;
      border-radius: 10px;
      color: #555;
}

.dim {
      font-size: 12px;
      color: #555;
}

.spent-money {
      padding: 10px;
}

.expense {
      line-height: 1.5;
      font-weight: 500;
      font-size: 30px;
}

.inside-pad {
      padding: 7px;
}

.graph img {
      margin-top: 10px;
      margin-bottom: 60px;
}

.available-text {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 16px;
}

.available-money .row {
      border-radius: 20px;
      background-color: rgba(122, 122, 122, 0.3);
      max-width: 100%;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      padding-left: 20px;
      margin: 20px auto;
}

.available-money .col-sm-6 {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 18px;
}

.available-money .col-sm-6 span {
      margin-bottom: 40px;
      font-size: 14px;
      color: rgb(87, 86, 86, 0.5);
}

.top {
      position: relative;
      top: 30px;
      padding: 0 5px;
}

.page-loader_page_loader__iOe6U {
      margin: 100px auto;
      width: 120px;
      height: 120px;
      border: 16px solid #f3f3f3;
      border-top-color: #00d2a8;
      border-radius: 50%;
      animation: page-loader_spin__tHJLi 2s linear infinite,
            page-loader_heart-beat__9Wu4P 2s linear infinite;
      background-color: #fff;
      text-align: center;
      line-height: 120px;
}

@keyframes page-loader_spin__tHJLi {
      0% {
            transform: rotate(0deg);
      }

      to {
            transform: rotate(1turn);
      }
}

@keyframes page-loader_heart-beat__9Wu4P {
      55% {
            background-color: #fff;
      }

      60% {
            background-color: #00d2a8;
      }

      65% {
            background-color: #fff;
      }

      70% {
            background-color: #00d2a8;
      }

      to {
            background-color: #fff;
      }
}

@keyframes loader_spin__gimJS {
      0% {
            transform: rotate(0deg);
      }

      to {
            transform: rotate(1turn);
      }
}

@keyframes loader_pulse__YBKcO {
      50% {
            background: #fff;
      }
}

.loader_loading__k1_ut {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-bottom: 12px;
      border: 0.25rem solid rgba(0, 159, 127, 0.3);
      border-top-color: #009f7f;
      animation: loader_spin__gimJS 1s linear infinite;
}

.loader_simple_loading__JrBP6 {
      border-radius: 50%;
      border: 3px solid rgba(0, 159, 127, 0.3);
      border-top-color: #009f7f;
      animation: loader_spin__gimJS 1s linear infinite;
}

/* Product */
.text-accent {
      color: #e86b19;
}

.text-accent:hover {
      color: #fa6704;
}

.bg-accent {
      background-color: #e86b19;
}

.bg-accent:hover {
      background-color: #fa6704;
}

.product__content {
      display: flex;
      padding: 1.25rem;
      margin-bottom: 2rem;
      flex-direction: column;
      border-radius: 0.25rem;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.product__content-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
}

.product__content-item__header {
      margin-bottom: 1rem;
}

.product__content-item__title {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
      min-width: max-content;
}

.product__content-item__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
}

.product__content-item__label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
}

.product__content-item__btn {
      position: absolute;
      padding: 0.5rem;
      outline: 2px solid transparent;
      left: 0.25rem;
      outline-offset: 2px;
      color: rgb(107, 114, 128);
      border: 0;
      background: transparent;
}

.product__content-item__input {
      display: flex;
      transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      font-size: 0.875rem;
      line-height: 1.25rem;
      align-items: center;
      width: 100%;
      height: 2.6rem;
      border-radius: 0.25rem;
      appearance: none;
      padding-inline-start: 3rem;
      padding-inline-end: 1rem;
      border: 1px solid rgb(170, 172, 174);
}

.product__content-add {
      display: inline-flex;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      font-weight: 600;
      line-height: 1;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      height: 2.6rem;
      border-radius: 0.25rem;
      border-width: 1px;
      border-color: transparent;
      outline: 0;
}

.product__content-filter {
      display: flex;
      margin-top: 1.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      white-space: nowrap;
      align-items: center;
      border: 0;
      background-color: transparent;
}

.product__content svg {
      width: 24px;
}

@media (min-width: 768px) {
      .product__content {
            padding: 1.25rem;
      }

      .product__content-item {
            flex-direction: row;
      }

      .product__content-item__header {
            margin-bottom: 0;
            width: 25%;
      }

      .product__content-item__body {
            flex-direction: row;
            width: 75%;
      }

      .product__content-filter {
            margin-top: 0;
      }
}

/* Product Table */
.table td {
      padding: 1rem 0.75rem;
      vertical-align: baseline;
}

.table tr td {
      border-width: 0 0 1px;
}

.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
}

.table__icon {
      height: 20px;
      width: 20px;
}

/* Product Create Form */
.font-semibold {
      font-weight: 600;
}

.form__header {
      display: flex;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      border-bottom-width: 1px;
      border-style: dashed;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-color: rgb(170, 172, 174);
}

.form__header-title {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
}

.form__group {
      display: flex;
      padding-bottom: 2rem;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      flex-wrap: wrap;
      border-bottom-width: 1px;
      border-style: dashed;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-color: rgb(170, 172, 174);
}

.form__group-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 1.25rem;
      width: 100%;
}

.form__group-title {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
}

.form__group-summary {
      font-size: 0.875rem;
      line-height: 1.25rem;
}

.form__group-body {
      padding: 1.25rem;
      width: 100%;
      border-radius: 0.25rem;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.form__group-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 9rem;
      border-radius: 0.25rem;
      border-width: 2px;
      border-style: dashed;
      cursor: pointer;
      border-color: rgb(170, 172, 174);
}

.form__group-upload svg {
      color: rgb(170, 172, 174);
}

.form__group-description {
      margin-top: 1rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
}

.form__group-drag {
      font-size: 0.75rem;
      line-height: 1rem;
}

@media (min-width: 640px) {
      .form__header {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
      }

      .form__group {
            margin-top: 2rem;
            margin-bottom: 2rem;
      }

      .form__group-header {
            padding-top: 2rem;
            padding-bottom: 2rem;
            width: 33.333333%;
      }

      .form__group-body {
            width: 66.666667%;
      }
}

@media (min-width: 768px) {
      .form__group-header {
            width: 33.333333%;
      }

      .form__group-body {
            padding: 2rem;
            width: 66.666667%;
      }
}

/* Form Product Details */
.form__group-information {
      display: flex;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      flex-wrap: wrap;
}

.form__group-label {
      display: block;
      margin-bottom: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 600;
      line-height: 1;
}

.form__group-input {
      display: flex;
      padding-left: 1rem;
      padding-right: 1rem;
      transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      font-size: 0.875rem;
      line-height: 1.25rem;
      align-items: center;
      width: 100%;
      height: 3rem;
      border-radius: 0.25rem;
      appearance: none;
      border: 1px solid rgb(170, 172, 174);
}

.form__group-status {
      display: block;
      margin-bottom: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 600;
      line-height: 1;
}

.form__group-message {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      font-size: 0.875rem;
      line-height: 1.25rem;
      width: 100%;
      border-radius: 0.25rem;
      border: 1px solid rgb(170, 172, 174);
      appearance: none;
}

@media (min-width: 640px) {
      .form__group-information {
            margin-top: 2rem;
            margin-bottom: 2rem;
      }
}

/* Radio Button */
.form__group-radio[type="radio"]:checked + label:before {
      border-color: var(--color-accent);
}

.form__group-radio[type="radio"]:checked,
.form__group-radio[type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
      display: none;
}

.form__group-radio[type="radio"]:checked + label,
.form__group-radio[type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 18px;
}

.form__group-radio[type="radio"]:checked + label:before,
.form__group-radio[type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid var(--color-border-base);
      border-radius: 100%;
      background-color: #fff;
}

.form__group-radio[type="radio"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
}

.form__group-radio[type="radio"]:checked + label:after,
.form__group-radio[type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: var(--color-accent);
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      transition: all 0.2s ease;
}

.form__group-btn {
      display: inline-flex;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      font-weight: 600;
      line-height: 1;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      height: 3rem;
      border-radius: 0.25rem;
      border-width: 1px;
      border-color: transparent;
      outline: 0;
}

.card-animate {
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
}

.card-animate:hover {
      -webkit-transform: translateY(calc(-1.5rem / 5));
      transform: translateY(calc(-1.5rem / 5));
      -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
      box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
      border-color: var(--color-accent);
}

.avatar-sm {
      height: 3rem;
      width: 3rem;
}

.avatar-title {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: var(--color-accent);
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-weight: 500;
      height: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
}

.sidebar-custom {
      height: 4rem;
      justify-content: space-between;
      padding: 0.85rem 1.3rem;
}

.mobile-navigation {
      background-color: #fff;
      padding: 10px 9px;
      border-radius: 36px;
      cursor: pointer;
      padding-bottom: 5px;
      box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}

/* Pagination */
.page-link {
      color: #e86b19;
}

.page-link:hover {
      color: #e86b19;
}

.page-link:focus {
      box-shadow: none;
}

.active > .page-link,
.page-link.active {
      background-color: #e86b19;
      border-color: #e86b19;
}

.pagination {
      margin-top: 20px;
      float: right;
}

/* skeleton */
.skeleton {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
}

.skeleton-post {
      width: 100%;
      height: 120px;
      margin-bottom: 20px;
      border-radius: 5px;
      background-color: #f5f5f5;
      animation: pulse 1.5s infinite;
}

.skeleton-table {
      width: 100%;
      height: 40px;
      margin-bottom: 20px;
      border-radius: 5px;
      background-color: #f5f5f5;
      animation: pulse 1.5s infinite;
}

@keyframes pulse {
      0% {
            opacity: 0.4;
      }
      50% {
            opacity: 0.8;
      }
      100% {
            opacity: 0.4;
      }
}

.post {
      margin-bottom: 20px;
}

.post h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
}

.post p {
      font-size: 16px;
      line-height: 1.5;
}
